input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.nav-link path {
  @apply stroke-[#9e9ebc];
}
.nav-link.active {
  @apply text-[#3369FD];
}

.nav-tabs .nav-link.active {
  @apply text-[#3369FD] border-[#3369FD];
}
.nav-link.active path,
.nav-link:hover path {
  @apply stroke-[#3369FD];
}

/* pagination */
.pagination {
  @apply my-7 flex justify-center;
}
.pagination a {
  @apply min-w-[30px] inline-flex items-center h-10 bg-F9F9FD hover:bg-224BB5/[0.1] text-base md:min-w-[40px] rounded-md cursor-pointer mx-0.5 py-1.5 md:py-2 px-4 disabled:cursor-not-allowed disabled:select-none duration-200 ease-linear;
}

.pagination__link--active a {
  @apply min-w-[30px] text-sm md:text-base md:min-w-[40px] rounded-md cursor-pointer mx-0.5 py-1.5 md:py-2 px-4 disabled:cursor-not-allowed disabled:select-none bg-3369FD text-white;
}

.pagination__link--disabled a {
  @apply min-w-[30px]  text-sm md:text-base md:min-w-[40px] rounded-md cursor-pointer mx-0.5 py-1.5 md:py-2 px-4 disabled:cursor-not-allowed disabled:select-none;
}

.react-tel-input .form-control {
  @apply w-full placeholder:text-zinc-500 border border-slate-200 min-h-[48px]  focus:outline-none rounded-lg text-base font-semibold;
}
.react-tel-input .flag-dropdown {
  @apply bg-white border border-slate-200 rounded-l-lg;
}
.react-datepicker {
  @apply shadow-2xl border-none;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply w-10 leading-10;
}
.react-datepicker__day--selected {
  @apply bg-3369FD;
}
.react-datepicker__navigation-icon {
  @apply top-3;
}
.react-datepicker__header {
  @apply border-none pt-4;
}

.profile_img > div {
  @apply w-[100px] h-[100px] rounded-full text-[10px] leading-normal p-1;
}

.profile_img > div .image-item,
.profile_img > div .image-item > div {
  @apply w-full h-full;
}

.profile_img > div .image-item > div img,
.profile_img > div .image-item > div > span {
  @apply rounded-full;
}

.custom_select .css-5mnlyn-control {
  @apply min-h-[48px] rounded-lg;
}
.custom_select .css-5mnlyn-control .css-1fdsijx-ValueContainer {
  @apply pl-4;
}
.custom_select
  .css-5mnlyn-control
  .css-1fdsijx-ValueContainer
  .css-1dimb5e-singleValue {
  @apply text-08162D;
}
.privacy-page p {
  @apply pl-5 mb-4 leading-8;
}
.privacy-page h1 {
  @apply font-semibold text-4xl leading-normal mb-7;
}
.privacy-page h2 {
  @apply font-semibold mb-5 text-3xl leading-normal;
}
.privacy-page h3 {
  @apply font-semibold mb-5 leading-normal text-lg;
}
.privacy-page h4 {
  @apply font-semibold mb-5 leading-normal text-base;
}
.privacy-page h5,
.privacy-page h6 {
  @apply font-semibold mb-5 leading-normal text-sm;
}
.privacy-page ul {
  @apply lg:pl-20 pl-12;
}
.privacy-page ul li {
  @apply relative mb-4 text-base leading-8 list-disc break-words;
}
.member_view_main .card.bg-white.shadow-shadow2.rounded-lg {
  @apply bg-transparent shadow-none rounded-none;
}
.member_view_main
  .card.bg-white.shadow-shadow2.rounded-lg
  .card-body.py-7.px-7 {
  @apply p-0;
}

.member_view_main .card.bg-white.shadow-shadow2.rounded-lg .card-header {
  @apply p-0 m-0 border-none;
}
.member_view_main
  .card.bg-white.shadow-shadow2.rounded-lg
  .card-body.py-7.px-7
  .table-wrapper
  .table-inner
  table {
  @apply bg-transparent;
}
.member_view_main
  .card.bg-white.shadow-shadow2.rounded-lg
  .card-body
  .data-table-wrapper
  .data-table-search {
  @apply xl:-mt-7 mt-0;
}
.right_action .relative.flex {
  @apply block;
}
.right_action .relative.flex p {
  @apply m-0;
}
.quill .ql-toolbar {
  @apply bg-white border-slate-200;
}
.quill .ql-container {
  @apply border-slate-200;
}
.gro_cat_listing .card {
  @apply bg-transparent shadow-none rounded-none;
}
.gro_cat_listing .card .card-header {
  @apply p-0 m-0 mb-7 border-none;
}
.gro_cat_listing .card .card-body {
  @apply p-0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
