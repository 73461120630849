@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./App.css);

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  color: #08162d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
